// For Add Item To Cart 
export const addCart = (product) => {
    return {
      type: "ADDITEM",
      payload: product,
    };
  };
  
  // For Del Item From Cart
  export const delCart = (product) => {
    return {
      type: "DELITEM",
      payload: product,
    };
  };
  
  // For Remove Item Completely From Cart
  export const removeCart = (product) => {
    return {
      type: "REMOVE_FROM_CART",
      payload: product,
    };
  };
  
  // For Clear Cart
  export const clearCart = () => {
    return {
      type: "CLEAR_CART",
    };
  };
   