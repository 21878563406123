import React from "react";
import { useState } from 'react';

import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { addCart, delCart, clearCart } from "../redux/action";


const Cart = () => {
  const state = useSelector((state) => state.handleCart);
  const dispatch = useDispatch();
  const [money, setMoney] = useState(0);

  const handleAdd = (item) => {
    dispatch(addCart(item));
  };
  const handleDel = (item) => {
    dispatch(delCart(item));
  };
  const handleClear = () => {
    dispatch(clearCart());
  };

  const handleAddMoney = () => {
    setMoney(money + 10); // Add INR 10
  };

  const handleMinusMoney = () => {
    setMoney(money > 0 ? money - 10 : 0); // Subtract INR 10, but not below 0
  };

  const emptyCart = () => {
    return (
      <div className="px-4 my-5 bg-light rounded-3 py-5">
        <div className="container py-4">
          <div className="row">
            <h3>Your Cart is Empty</h3>
          </div>
        </div>
      </div>
    );
  };

  
  const cartItems = (product) => {
    return (
      <>
        <div className="px-4 my-5 bg-light rounded-3 py-5">
          <div className="container py-4">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <img
                  src={product.image}
                  alt={product.title}
                  height="200px"
                  width="180px"
                />
              </div>
              <div className="col-md-4">
                <h3>{product.title}</h3>
                <p className="lead fw-bold">
                  {product.qty} X ${product.price} = $
                  {product.qty * product.price}
                </p>
                <button
                  className="btn btn-outline-dark me-4"
                  onClick={() => handleDel(product)}
                >
                  <i className="fa fa-minus"></i>
                </button>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => handleAdd(product)}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const buttons = () => {
    return (
      <>
        <div className="container">
          <div className="row">
            <NavLink
              to="/checkout"
              className="btn btn-outline-dark mb-5 w-25 mx-auto"
            >
              Proceed to Checkout
            </NavLink>
            <button
              className="btn btn-outline-dark mb-5 w-25 mx-auto"
              onClick={handleClear}
            >
              Clear Cart
            </button>
          </div>
        </div>
      </>
    );
  };

  const totalPrice = state.reduce(
    (acc, curr) => acc + curr.price * curr.qty,
    0
  );
  
  return (
    <div>
      {state.length === 0 && emptyCart()}
      {state.length !== 0 && state.map(cartItems)}
      <div className="px-4 my-5 bg-light rounded-3 py-5">
        <div className="container py-4">
          <div className="row justify-content-end">
            <h3 className="me-4">Total Price: ${totalPrice}</h3>
          </div>
        </div>
      </div>
      {state.length !== 0 && buttons()}

      {/* <div className="container my-5">
        <div className="row justify-content-center">
          <button
            className="btn btn-outline-dark me-4"
            onClick={handleAddMoney}
          >
            Add Money
          </button>
          <button
            className="btn btn-outline-dark me-4"
            onClick={handleMinusMoney}
          >
            Subtract Money
          </button>
          <h4 className="mt-3">Money: INR {money}</h4>
          {money === 0 && (
            <NavLink to="/payment" className="btn btn-outline-success mt-3">
              Payment Option
            </NavLink>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default Cart;
